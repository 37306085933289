<template>
  <BaseCard
    heading="Active Presets"
    icon="collections"
    :height="$vuetify.breakpoint.smAndUp ? '514' : ''"
  >
    <DataTableExtended
      ref="table"
      :calculate-widths="true"
      :headers="headers"
      :items="presets"
      :options.sync="options"
      :footerOptions="footerOptions"
      :items-per-page.sync="options.itemsPerPage"
      :server-items-length="presets_count"
      class="elevation-0 border"
      :loading="loading"
      :loading-text="loading ? 'Loading…' : 'No data available'"
      @update:options="fetchTableData(options)"
      :mobile-breakpoint="0"
      :withUrlParams="false"
      data-testid="table-active-presets"
    >
      <template v-slot:top>
        <v-row class="pa-3">
          <v-col cols="12" md="12">
            <v-text-field
              outlined
              v-model="serverPagination.search"
              label="Search"
              single-line
              hide-details
              clearable
              data-testid="filter-search-active-presets"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.play`]="{ item }">
        <v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              x-small
              :color="Pallette.actionButtons.play"
              v-on="on"
              @click="gotoActivePreset(item)"
              data-testid="btn-open-preset"
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </template>
          <span>Go to preset</span>
        </v-tooltip>
        <v-btn
          v-else
          block
          dark
          small
          :color="Pallette.actionButtons.play"
          @click="gotoActivePreset(item)"
          data-testid="btn-open-preset"
        >
          <v-icon>mdi-play</v-icon>
          Go to preset
        </v-btn>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
    </DataTableExtended>
  </BaseCard>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import DataTableExtended from "@/components/table/DataTableExtended"

export default {
  name: "ActivePresetsWidget",

  components: { DataTableExtended },

  mixins: [GeneralMixin],

  data: () => ({
    selected: [],
    headers: [
      {
        text: "",
        value: "",
        sortable: false,
      },
      {
        text: "Preset",
        align: "left",
        value: "name",
        sortable: true,
      },
    ],
    loading: false,
    presets: [],
    presets_count: 0,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    footerOptions: {
      "items-per-page-text": "",
      "items-per-page-options": [5],
    },
    serverPagination: {
      p: 1,
      pp: 5,
      sort: "",
      order: "",
    },
    search: "",
  }),

  mounted: function () {
    if (this.isAuthenticated) this.fetchTableData(this.$refs?.table?.options)
  },

  watch: {
    search() {
      this.updateRoute(this.$refs?.tableActive?.options)
    },
  },

  methods: {
    fetchTableData(options) {
      let self = this

      if (options) {
        self.serverPagination.sort =
          options.sortBy && options.sortBy[0] ? options.sortBy[0] : "name"
        self.serverPagination.order =
          options.sortDesc && options.sortDesc[0] ? "DESC" : "ASC"
      }

      let params = {
        p: self.options.page,
        pp: self.options.itemsPerPage,
        // keep: '_key' // @todo define what need to keep in response
      }
      if (self.search !== "") params.search = self.search
      if (self.serverPagination.sort !== "")
        params.sort = self.serverPagination.sort
      if (self.serverPagination.order !== "")
        params.order = self.serverPagination.order
      self.loading = true
      self.$store
        .dispatch("presetsEnginesGetList", { params })
        .then(function (response) {
          self.presets = response?.data?.data?.result || []
          self.presets_count = response?.data?.data?.full_count || 0
          self.loading = false
        })
    },
    gotoActivePreset(item) {
      this.$router
        .push({
          path: "/engines/unattended",
          query: { presets: [item.name] },
        })
        .catch((err) => {})
    },
  },

  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      Pallette: "get_Pallette",
    }),
  },
}
</script>
